import React, { ChangeEventHandler } from 'react'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Layout from '../components/Layout/Layout'

const Content = styled.section`
  ${tw`container pt-8`}

  p {
    ${tw`mb-8 font-light`}
  }

  input:checked ~ .dot {
    transform: translateX(100%);
    ${tw`bg-green-500`}
  }
`

export function useToggle(initialValue = false) {
  const [value, setValue] = React.useState(initialValue)
  const toggle = React.useCallback(() => {
    setValue(v => !v)
  }, [])
  return [value, toggle]
}

const BuyButtonsPage: React.FC = () => {
  const [isOn, toggleIsOn] = useToggle()
  const url = isOn
    ? `https://www-local.greatcall.com/cart/start`
    : `/cart/start`

  return (
    <Layout>
      <SEO title="Buy Buttons" description="" noIndex={true} />
      <div className={`gradient-3-bby mx-auto max-w-1920 md:h-[22rem]`}>
        <div className="flex h-full flex-wrap content-center">
          <div className={`container py-12 text-left text-white`}>
            <h1 className={`mb-4 leading-none`}>Buy Buttons Page</h1>
          </div>
        </div>
      </div>

      <Content className="py-8">
        <div className="mb-12 flex w-full items-center justify-center">
          <label htmlFor="toggleB" className="flex cursor-pointer items-center">
            <div className="relative">
              <input
                type="checkbox"
                id="toggleB"
                className="sr-only"
                onChange={
                  toggleIsOn as ChangeEventHandler<HTMLInputElement> | undefined
                }
              />
              <div className="block h-8 w-14 rounded-full bg-gray-600"></div>
              <div className="dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition"></div>
            </div>
            <div className="ml-3 font-medium text-gray-700">Locals Only</div>
          </label>
        </div>

        <h2 className="mb-4 leading-none">Buy Web</h2>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart3"
          data-product-price="112.49"
          data-product-sku="GC006A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="JitterbugTouch6" />
          <input type="hidden" name="DeviceTypeId" value="touch6" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart3 Web
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart4"
          data-product-price="112.49"
          data-product-sku="GC009A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="JitterbugTouch7" />
          <input type="hidden" name="DeviceTypeId" value="touch7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart4 Web
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="GC005A-W-RED"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="JitterbugFlip2Red" />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Red Web
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="GC005A-W-GRA"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugFlip2Graphite"
          />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Graphite Web
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile Plus"
          data-product-price="49.99"
          data-product-sku="GC06A-W-SLV"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="5Star4Silver" />
          <input type="hidden" name="DeviceTypeId" value="r4" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM+ Web
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile2"
          data-product-price="49.99"
          data-product-sku="GC07A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="5Star5Black" />
          <input type="hidden" name="DeviceTypeId" value="r5" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM2 Web
          </button>
        </form>
        <h2 className="mb-4 leading-none">Buy Mobile</h2>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart3"
          data-product-price="112.49"
          data-product-sku="GC006A-M-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugTouch6Accessory"
          />
          <input type="hidden" name="DeviceTypeId" value="touch6" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart3 Mobile
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart4"
          data-product-price="112.49"
          data-product-sku="GC009A-M-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugTouch7Accessory"
          />
          <input type="hidden" name="DeviceTypeId" value="touch7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart4 Mobile
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="GC005A-M-RED"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugFlip2RedAccessory"
          />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Red Mobile
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="GC005A-M-GRA"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugFlip2GraphiteAccessory"
          />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="PackageId" value="LivelyPreferredPP" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Graphite Mobile
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile Plus"
          data-product-price="49.99"
          data-product-sku="GC06A-M-SLV"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="5Star4SilverAccessory" />
          <input type="hidden" name="DeviceTypeId" value="r4" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM+ Mobile
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile2"
          data-product-price="49.99"
          data-product-sku="GC07A-M-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="5Star5BlackAccessory" />
          <input type="hidden" name="DeviceTypeId" value="r5" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM2 Mobile
          </button>
        </form>
        <h2 className="mb-4 leading-none">Buy Retail Ready (RR)</h2>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart3"
          data-product-price="112.49"
          data-product-sku="RR-GC006A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugTouch6Accessory2"
          />
          <input type="hidden" name="DeviceTypeId" value="touch6" />
          <input type="hidden" name="skipAll" value="true" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart3 Retail Ready
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Smart4"
          data-product-price="112.49"
          data-product-sku="RR-GC009A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugTouch7Accessory2"
          />
          <input type="hidden" name="DeviceTypeId" value="touch7" />
          <input type="hidden" name="skipAll" value="true" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Smart4 Retail Ready
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="RR-GC005A-W-RED"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugFlip2RedAccessory2"
          />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="skipAll" value="true" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Red Retail Ready
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="phone"
          data-product-name="Jitterbug Flip2"
          data-product-price="74.99"
          data-product-sku="RR-GC005A-W-GRA"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="JitterbugFlip2GraphiteAccessory2"
          />
          <input type="hidden" name="DeviceTypeId" value="j7" />
          <input type="hidden" name="skipAll" value="true" />
          <button type="submit" className="btn-primary w-max text-center">
            Buy Flip Graphite Retail Ready
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile Plus"
          data-product-price="49.99"
          data-product-sku="RR-GC06A-W-SLV"
          method="post"
          className={`mb-2 block`}
        >
          <input
            name="ProductId"
            type="hidden"
            value="5Star4SilverAccessory2"
          />
          <input type="hidden" name="DeviceTypeId" value="r4" />
          <input type="hidden" name="skipAll" value="true" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM+ Retail Ready
          </button>
        </form>
        <form
          action={url}
          data-link-type="cart"
          data-product-category="medical alert"
          data-product-name="Lively Mobile2"
          data-product-price="49.99"
          data-product-sku="RR-GC07A-W-BLK"
          method="post"
          className={`mb-2 block`}
        >
          <input name="ProductId" type="hidden" value="5Star5BlackAccessory2" />
          <input type="hidden" name="DeviceTypeId" value="r5" />
          <input type="hidden" name="skipAll" value="true" />
          <input
            name="PackageId"
            type="hidden"
            value="LivelyMobilePlusBasicPackage"
          />
          <button type="submit" className="btn-primary w-max text-center">
            Buy LM2 Retail Ready
          </button>
        </form>
        <h2 className="mb-4 leading-none">Ensighten</h2>
        <button
          className="btn-primary w-max text-center"
          onClick={() => ensBootstraps.lively_dns_gw.gateway.openModal()}
        >
          Ensighten Modal
        </button>
      </Content>
    </Layout>
  )
}

export default BuyButtonsPage
